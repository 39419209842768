var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }]
  }, [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "load-dictionaries": _vm.loadDictionaries,
      "dataset-name": "product-reports/product-details",
      "show-labels": false,
      "compact": true,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "change": _vm.onFilteringPanelChange,
      "search": function search($event) {
        return _vm.getData(0);
      },
      "reset": _vm.onFilteringPanelReset
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart1-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplateAllMetrics)
    }
  })]), _c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "title": "Product sales (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": "Comparison of sales by periods",
      "type": _vm.charts.chart1.type,
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart1-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel1",
          attrs: {
            "mode": "server",
            "dataset-name": "product-details/products-sales-chart-1",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel1.filters,
            "loaded": _vm.filteringPanel1.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(1);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          attrs: {
            "lg": "2",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart1.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart1
          },
          model: {
            value: _vm.charts.chart1.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart1.pagination, "value", $$v);
            },
            expression: "charts.chart1.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isBarChart,
            expression: "charts.chart1.isBarChart"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isBarChart = !_vm.charts.chart1.isBarChart;
              _vm.charts.chart1.type = 'BarChart';
              _vm.updateChart1();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-line"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart1.isBarChart,
            expression: "!charts.chart1.isBarChart"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart1.isBarChart = !_vm.charts.chart1.isBarChart;
              _vm.charts.chart1.type = 'ColumnChart';
              _vm.updateChart1();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "title": "Total sales comparison (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": "Comparison of sales by periods",
      "default-mode": _vm.charts.chart2.isDefaultMode,
      "type": _vm.charts.chart2.type,
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart2-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_vm._v(" Compare to: "), _c('filtering-panel', {
          ref: "filteringPanel2",
          attrs: {
            "mode": "server",
            "dataset-name": "product-details-chart-2",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel2.filters,
            "loaded": _vm.filteringPanel2.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(2);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-form-checkbox', {
          attrs: {
            "name": "checkboxGroupByYears2",
            "inline": "",
            "switch": ""
          },
          on: {
            "input": _vm.updateChart2
          },
          model: {
            value: _vm.charts.chart2.groupByYearsView,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart2, "groupByYearsView", $$v);
            },
            expression: "charts.chart2.groupByYearsView"
          }
        }, [_vm._v(" Group by years ")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isColumnType,
            expression: "charts.chart2.isColumnType"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isColumnType = !_vm.charts.chart2.isColumnType;
              _vm.charts.chart2.type = 'LineChart';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-line"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart2.isColumnType,
            expression: "!charts.chart2.isColumnType"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart2.isColumnType = !_vm.charts.chart2.isColumnType;
              _vm.charts.chart2.type = 'ColumnChart';
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    ref: "chart3container"
  }, [_c('g-chart-custom', {
    ref: "chart3",
    attrs: {
      "id": "chart3",
      "title": "Total sales (".concat(_vm.selectedMetric().label, ")"),
      "show-tabs": true,
      "default-mode": _vm.charts.chart3.isDefaultMode,
      "subtitle": "",
      "loading": _vm.charts.chart3.loading,
      "type": "AnnotationChart",
      "settings": {
        packages: ['annotationchart']
      },
      "data": _vm.charts.chart3.chartData,
      "options": _vm.charts.chart3.chartOptions
    },
    on: {
      "ready": _vm.onTotalSalesReady
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart3-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('filtering-panel', {
          ref: "filteringPanel3",
          attrs: {
            "mode": "server",
            "dataset-name": "product-details/product-sales-chart-3",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel3.filters,
            "loaded": _vm.filteringPanel3.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(3);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3.isDefaultMode,
            expression: "charts.chart3.isDefaultMode"
          }]
        }, [_c('b-button-group', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3.isDefaultMode,
            expression: "charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart3ZoomClick(3);
            }
          }
        }, [_vm._v(" 3 months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart3ZoomClick(6);
            }
          }
        }, [_vm._v(" 6 months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart3ZoomClick(12);
            }
          }
        }, [_vm._v(" 1 year ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart3ZoomClick(24);
            }
          }
        }, [_vm._v(" 2 years ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onChart3ZoomClick(60);
            }
          }
        }, [_vm._v(" 5 years ")])], 1)], 1), _c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart3.isDefaultMode,
            expression: "!charts.chart3.isDefaultMode"
          }]
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "checkboxGroupByYears",
            "inline": "",
            "switch": ""
          },
          on: {
            "input": _vm.updateChart3Table
          },
          model: {
            value: _vm.chart3Table.groupByYearsView,
            callback: function callback($$v) {
              _vm.$set(_vm.chart3Table, "groupByYearsView", $$v);
            },
            expression: "chart3Table.groupByYearsView"
          }
        }, [_vm._v(" Group by years ")]), _c('b-form-checkbox', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.chart3Table.groupByYearsView,
            expression: "chart3Table.groupByYearsView"
          }],
          attrs: {
            "name": "checkboxYTD",
            "inline": ""
          },
          on: {
            "input": _vm.updateChart3Table
          },
          model: {
            value: _vm.chart3Table.YTD,
            callback: function callback($$v) {
              _vm.$set(_vm.chart3Table, "YTD", $$v);
            },
            expression: "chart3Table.YTD"
          }
        }, [_vm._v(" Limit to YTD ")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart3.isDefaultMode,
            expression: "charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3.isDefaultMode = !_vm.charts.chart3.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.charts.chart3.isDefaultMode,
            expression: "!charts.chart3.isDefaultMode"
          }],
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart3.isDefaultMode = !_vm.charts.chart3.isDefaultMode;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart3Table.name,
            "loading": _vm.chart3Table.isLoading,
            "data": _vm.chart3Table.dataSet,
            "options": _vm.chart3Table.options
          },
          on: {
            "mounted": _vm.onChart3TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)])], 1), _c('p'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart4-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplateAllMetrics)
    }
  })]), _c('g-chart-custom', {
    ref: "chart4",
    attrs: {
      "default-mode": _vm.charts.chart4.isDefaultMode,
      "title": "Sales by markets (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": _vm.charts.chart4.subtitle,
      "type": _vm.charts.chart4.type,
      "loading": _vm.charts.chart4.loading,
      "data": _vm.charts.chart4.chartData,
      "options": _vm.charts.chart4.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart4-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel4",
          attrs: {
            "mode": "server",
            "dataset-name": "product-details/products-sales-chart-4",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel4.filters,
            "loaded": _vm.filteringPanel4.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(4);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart4.isDefaultMode,
            expression: "charts.chart4.isDefaultMode"
          }],
          attrs: {
            "lg": "6",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart4.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart4
          },
          model: {
            value: _vm.charts.chart4.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart4.pagination, "value", $$v);
            },
            expression: "charts.chart4.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isColumnType = false;
              _vm.charts.chart4.type = 'PieChart';
              _vm.charts.chart4.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isColumnType = true;
              _vm.charts.chart4.type = 'BarChart';
              _vm.charts.chart4.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart4.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart4Table.name,
            "loading": _vm.chart4Table.isLoading,
            "data": _vm.chart4Table.dataSet,
            "options": _vm.chart4Table.options
          },
          on: {
            "mounted": _vm.onChart4TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-popover', {
    attrs: {
      "target": "chart5-help",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.popoverTemplateAllMetrics)
    }
  })]), _c('g-chart-custom', {
    ref: "chart5",
    attrs: {
      "default-mode": _vm.charts.chart5.isDefaultMode,
      "title": "Top 100 accounts sales (".concat(_vm.selectedMetric().label, ")"),
      "subtitle": _vm.charts.chart5.subtitle,
      "type": _vm.charts.chart5.type,
      "loading": _vm.charts.chart5.loading,
      "data": _vm.charts.chart5.chartData,
      "options": _vm.charts.chart5.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "legend",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-question-circle",
          attrs: {
            "id": "chart5-help"
          }
        })];
      },
      proxy: true
    }, {
      key: "filter",
      fn: function fn() {
        return [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12",
            "md": "12",
            "lg": "12"
          }
        }, [_c('filtering-panel', {
          ref: "filteringPanel5",
          attrs: {
            "mode": "server",
            "dataset-name": "product-details/products-sales-chart-5",
            "show-labels": false,
            "compact": true,
            "hide-reset-button": true,
            "filters": _vm.filteringPanel5.filters,
            "loaded": _vm.filteringPanel5.loaded
          },
          on: {
            "search": function search($event) {
              return _vm.getData(5);
            }
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart5.isDefaultMode,
            expression: "charts.chart5.isDefaultMode"
          }],
          attrs: {
            "lg": "6",
            "sm": "12",
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "pills": "",
            "hide-ellipsis": true,
            "total-rows": _vm.charts.chart5.pagination.total,
            "per-page": 10
          },
          on: {
            "input": _vm.updateChart5
          },
          model: {
            value: _vm.charts.chart5.pagination.value,
            callback: function callback($$v) {
              _vm.$set(_vm.charts.chart5.pagination, "value", $$v);
            },
            expression: "charts.chart5.pagination.value"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button-group', [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isColumnType = false;
              _vm.charts.chart5.type = 'PieChart';
              _vm.charts.chart5.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-pie"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isColumnType = true;
              _vm.charts.chart5.type = 'BarChart';
              _vm.charts.chart5.isDefaultMode = true;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "chart-bar"
          }
        })], 1), _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.charts.chart5.isDefaultMode = false;
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "list"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "table",
      fn: function fn() {
        return [_c('table-custom', {
          attrs: {
            "name": _vm.chart5Table.name,
            "loading": _vm.chart5Table.isLoading,
            "data": _vm.chart5Table.dataSet,
            "options": _vm.chart5Table.options
          },
          on: {
            "mounted": _vm.onChart5TableMount
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }