export default {
  filteringPanel: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Product",
        tooltip: "Product name",
        name: "product",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Metrics",
        tooltip: "Reports metrics",
        name: "metric",
        trackby: "id",
        label: "label",
        multiple: false,
        allowEmpty: false,
        options: [
          {
            id: "sold",
            label: "$ Sold"
          },
          {
            id: "bottles",
            label: "Bottles"
          },
          {
            id: "alc_amount",
            label: "Amount of alcohol"
          }
        ],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      }
    ]
  },
  filteringPanel1: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Product",
        tooltip: "Product name",
        name: "product",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period2",
        options: []
      }
    ]
  },
  filteringPanel2: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Product",
        tooltip: "Product name",
        name: "product",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period2",
        options: []
      }
    ]
  },

  filteringPanel3: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Product",
        tooltip: "Product name",
        name: "product",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      }
    ]
  },
  filteringPanel4: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Product",
        tooltip: "Product name",
        name: "product",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      }
    ]
  },
  filteringPanel5: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "string",
        title: "Product",
        tooltip: "Product name",
        name: "product",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "Chain",
        tooltip: "Account billing chain",
        name: "chain",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {}
      }
    ]
  }
};
