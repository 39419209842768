<template>
  <div class="w-100">
    <b-row align-h="start">
      <b-col sm="12" md="12" lg="3">
        <b-row>
          <b-col>
            <h4 class="float-left">
              {{ title }}
              <slot name="legend" />
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>{{ subtitle }}</b-col>
        </b-row>
      </b-col>
      <b-col sm="12" md="12" lg="8">
        <slot name="filter" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="isDataAvailable">
        <slot name="chartTopBar" />

        <GChart
          v-if="defaultMode"
          ref="chart"
          :settings="settings"
          :type="type"
          :data="this.dataSet"
          :options="options"
          :events="events"
          @ready="onChartReady"
          :resize-debounce="500"
        />

        <div v-show="!defaultMode && isDataAvailable">
          <slot name="table" />
        </div>

        <slot name="chartBottomBar" />
      </b-col>

      <!--  <div><slot name="table"></slot></div> -->

      <b-col v-if="!isDataAvailable">
        <b-overlay
          :show="loading"
          :opacity="0.5"
          spinner-variant="secondary"
          rounded="sm"
        >
          <div
            :style="
              `text-align:center;height: ${options.height}px;line-height:${options.height}px;`
            "
          >
            No data available
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
///
import { GChart } from 'vue-google-charts'

export default {
  components: {
    GChart
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    data: {
      type: [Array, Object],
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    settings: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    showTabs: {
      type: Boolean,
      default: false
    },
    defaultMode: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      //loading: false,
      activeTab: 0,
      dataSet: null,
      events: {
        select: () => {
          const selection = this.$refs.chart.chartObject.getSelection()

          const data = this.$refs.chart.data
          this.$emit('chart-element-selected', {
            data: data,
            selection: selection[0]
          })
        }
      }
    }
  },
  computed: {
    isDataAvailable: function () {
      if (!this.dataSet) return false
      return this.dataSet.length > 1
    }
  },
  mounted: function () {},
  methods: {
    onChartReady (chart, google) {
      this.$emit('ready', {
        chart,
        google
      })
    }
  },
  watch: {
    loading: function (newValue) {
      //this.dataSet = this.data

      if (newValue) {
        this.dataSet = null
      }
    },
    data: function (newValue) {
      this.dataSet = newValue
    }
  }
}
</script>

<style></style>
