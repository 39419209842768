var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('b-row', {
    attrs: {
      "align-h": "start"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "3"
    }
  }, [_c('b-row', [_c('b-col', [_c('h4', {
    staticClass: "float-left"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm._t("legend")], 2)])], 1), _c('b-row', [_c('b-col', [_vm._v(_vm._s(_vm.subtitle))])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "8"
    }
  }, [_vm._t("filter")], 2)], 1), _c('b-row', [_vm.isDataAvailable ? _c('b-col', [_vm._t("chartTopBar"), _vm.defaultMode ? _c('GChart', {
    ref: "chart",
    attrs: {
      "settings": _vm.settings,
      "type": _vm.type,
      "data": this.dataSet,
      "options": _vm.options,
      "events": _vm.events,
      "resize-debounce": 500
    },
    on: {
      "ready": _vm.onChartReady
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.defaultMode && _vm.isDataAvailable,
      expression: "!defaultMode && isDataAvailable"
    }]
  }, [_vm._t("table")], 2), _vm._t("chartBottomBar")], 2) : _vm._e(), !_vm.isDataAvailable ? _c('b-col', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_c('div', {
    style: "text-align:center;height: ".concat(_vm.options.height, "px;line-height:").concat(_vm.options.height, "px;")
  }, [_vm._v(" No data available ")])])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }